/**
 * TV_S Main JS file
 *
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish (via https://github.com/roots/sage)
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * @version 0.1.0
 *
 */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages

                // Fitvids Target your .container, .wrapper, .post, etc.
                $('.content-block').fitVids();

                // top margin for sticky clearance
                if ( $(window).width() < 600 ) {
                    $('.header-area').removeClass('sticky');
                } else {
                    $siteMargin = ( $('.after-header').length > 0 ) ? $('.after-header') : $('#wrapper');
                    $('.site-branding').imagesLoaded(function(){
                        $siteMargin
                            .css('margin-top', $('.header-area').outerHeight())
                            .visibility({
                                continuous: true,
                                onTopPassed: function(calculations) {
                                    $('.header-area #masthead').addClass('shrink');
                                },
                                onTopVisible: function(calculations) {
                                    $('.header-area #masthead').removeClass('shrink');
                                }
                            });
                    });
                }

                // enable sidebar toggle
                $('.primary-navigation')
                    .sidebar('setting', {
                        dimPage: false,
                        scrollLock: true
                    })
                    .sidebar('attach events', '.flyout-navigation .ui.button', 'toggle');

                // fix gravity forms tabindexes
                if( $('.gform_wrapper').length > 0 ) {
                    var tabindex = 15;
                    $('.gform_wrapper [tabindex]').each(function(i,e){
                        $(this).attr('tabindex',tabindex++);
                    });
                }
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Bio page
        'page__ronjon_bio': {
            init: function() {
                // JavaScript to be fired on the bio page
                var $te = $('.timeline-express').masonry({
                    // disable initial layout
                    initLayout: false
                });
                // bind event
                $te.masonry('on', 'layoutComplete', function(event, laidOutItems){
                    var msnryHeight = 0;
                    $('.timeline-express .cd-timeline-block').each(function(i,e){
                        msnryHeight += $(this).height();
                    });
                    $('.timeline-express').height(msnryHeight);
                });
                // trigger initial layout
                $te.masonry();
            },
            finalize: function() {
                // JavaScript to be fired on the bio page, after the init JS
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
                // slick carousels
                var slicks = $('.slick'),
                    s = 1;

                $('.slick').slick({
                    infinite: false,
                    arrows: true,
                    dots: true
                });
                $('.coalitions .slick').slick('slickSetOption', {
                    dots: false,
                    slidesToShow: 3,
                    responsive: [
                        {
                            breakpoint: 990,
                            settings: {
                                centerMode: true,
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                arrows: false,
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                }, true);
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
		// Donations CPT
		'donations': {
			init: function() {
				// JavaScript to be fired on Donation pages
				$('body').on('dm-ty-rendered', function() {
					$('body').addClass('tvdm-thank-you');
				});
			}
		},
		// TV Landing CPT
		'single-tvlanding': {
			init: function() {
				// JavaScript to be fired on TV Landing pages

			}
		}
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
